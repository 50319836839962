import React, { useState, useEffect, useCallback } from 'react';
import Map, { Marker, Source, Layer, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { getFirestore, collectionGroup, getDocs, collection } from 'firebase/firestore';
import { getApp } from 'firebase/app';
import './MapReport.css';

// Mapbox default color palette
const colorPalette = [
  '#1e88e5', // Blue
  '#43a047', // Green
  '#fdd835', // Yellow
  '#fb8c00', // Orange
  '#e53935', // Red
  '#8e24aa', // Purple
];

// Function to generate circle points
function generateCirclePoints(centerLat, centerLon, radiusMiles, numPoints = 64) {
  const points = [];
  for (let i = 0; i < numPoints; i++) {
    const angle = (i / numPoints) * 2 * Math.PI;
    const dx = radiusMiles * Math.cos(angle);
    const dy = radiusMiles * Math.sin(angle);
    // Approximate conversion to longitude/latitude
    const lon = centerLon + (dx / 69.172) / Math.cos(centerLat * Math.PI / 180);
    const lat = centerLat + (dy / 69.172);
    points.push([lon, lat]);
  }
  return points;
}

function MapReport({ onBack }) {
  const [markers, setMarkers] = useState([]);
  const [viewState, setViewState] = useState({
    longitude: -98.5795,
    latitude: 39.8283,
    zoom: 3,
  });
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [activeOverlays, setActiveOverlays] = useState(['Contractors']);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locations] = useState({
    'Las Vegas': { longitude: -115.1398, latitude: 36.1699, zoom: 10 },
    'New York City': { longitude: -74.0060, latitude: 40.7128, zoom: 10 },
    'Olympia': { longitude: -122.9007, latitude: 47.0379, zoom: 10 },
    'Orlando': { longitude: -81.3792, latitude: 28.5383, zoom: 10 },
    'Palm Beach': { longitude: -80.0364, latitude: 26.7056, zoom: 10 },
    'Wichita': { longitude: -97.3375, latitude: 37.6872, zoom: 10 },
  });
  const [homeownerData, setHomeownerData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGeocoordinates = async () => {
      try {
        const db = getFirestore(getApp());
        const geocoordinatesCollection = collection(db, 'prd_geocoordinates');
        const geocoordinatesSnapshot = await getDocs(geocoordinatesCollection);
        const geocoordinatesList = geocoordinatesSnapshot.docs
          .map((doc, index) => ({
            id: doc.id,
            ...doc.data().geocoordinate,
            color: colorPalette[index % colorPalette.length]
          }))
          .filter(marker => 
            typeof marker.latitude === 'number' && 
            typeof marker.longitude === 'number' &&
            !isNaN(marker.latitude) && 
            !isNaN(marker.longitude) &&
            typeof marker.travelDistance === 'string' &&
            !isNaN(parseFloat(marker.travelDistance))
          );
        setMarkers(geocoordinatesList);
      } catch (err) {
        console.error("Error fetching geocoordinates:", err);
        setError("Failed to fetch contractor data. Please try again later.");
      }
    };

    fetchGeocoordinates();
  }, []);

  useEffect(() => {
    const fetchHomeownerData = async () => {
      try {
        const db = getFirestore(getApp());
        const homesSnapshot = await getDocs(collectionGroup(db, 'homes'));
        const homesList = homesSnapshot.docs
          .map(doc => doc.data())
          .filter(data => data.geocoordinate && data.geocoordinate.latitude && data.geocoordinate.longitude);
        setHomeownerData(homesList);
      } catch (err) {
        console.error("Error fetching homeowner data:", err);
        if (err.code === 'failed-precondition') {
          setError("This operation requires an index. Please contact the administrator.");
        } else {
          setError("Failed to fetch homeowner data. Please try again later.");
        }
      }
    };

    fetchHomeownerData();
  }, []);

  const handleViewStateChange = useCallback((event) => {
    setViewState(event.viewState);
  }, []);

  const handleMarkerClick = useCallback((e, marker) => {
    e.originalEvent.stopPropagation();
    setHoveredMarker(currentHoveredMarker => 
      currentHoveredMarker && currentHoveredMarker.id === marker.id ? null : marker
    );
  }, []);

  const toggleOverlay = (overlay) => {
    setActiveOverlays(current => {
      const newOverlays = current.includes(overlay)
        ? current.filter(item => item !== overlay)
        : [...current, overlay];
      
      // If Contractors overlay is being deactivated, close any open pin details
      if (overlay === 'Contractors' && !newOverlays.includes('Contractors')) {
        setHoveredMarker(null);
      }
      
      return newOverlays;
    });
  };

  const jumpToLocation = (location) => {
    if (locations[location]) {
      setViewState(locations[location]);
      setSelectedLocation(location);
    }
  };

  return (
    <div className="map-report">
      <div className="beta-stamp">BETA</div>
      <div className="map-report-header">
        <div className="header-controls">
          <button className="back-button" onClick={onBack}>
            <span className="back-arrow">&#8592;</span>
            Back to Reports
          </button>
          <div className="jump-to-container">
            <select 
              className="jump-to-select"
              onChange={(e) => jumpToLocation(e.target.value)}
              value={selectedLocation}
            >
              <option value="" disabled={selectedLocation !== ''}>
                {selectedLocation === '' ? 'Jump to location' : selectedLocation}
              </option>
              {Object.keys(locations).map(location => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </select>
            <div className="arrow-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="white">
                <path d="M4 6l4 4 4-4H4z"/>
              </svg>
            </div>
          </div>
          <button
            className={`overlay-button ${activeOverlays.includes('Contractors') ? 'active' : ''}`}
            onClick={() => toggleOverlay('Contractors')}
          >
            Contractors
          </button>
          <button
            className={`overlay-button ${activeOverlays.includes('Service Coverage') ? 'active' : ''}`}
            onClick={() => toggleOverlay('Service Coverage')}
          >
            Service Coverage
          </button>
          <button
            className={`overlay-button ${activeOverlays.includes('Homeowner Density') ? 'active' : ''}`}
            onClick={() => toggleOverlay('Homeowner Density')}
          >
            Homeowner Density
          </button>
          <button
            className={`overlay-button ${activeOverlays.includes('Order Heatmap') ? 'active' : ''}`}
            onClick={() => toggleOverlay('Order Heatmap')}
          >
            Order Heatmap
          </button>
        </div>
      </div>
      <div className="map-container">
        <Map
          {...viewState}
          onMove={handleViewStateChange}
          mapboxAccessToken="pk.eyJ1IjoiY2hhc2Vob21lcnVubnMiLCJhIjoiY20xanc3cTBmMDJ5YjJrbjJ6OGE0ZHY2ZSJ9.o3HP8_cKGFuz00ObNeE61Q"
          style={{width: '100%', height: '100%'}}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          onClick={() => setHoveredMarker(null)}
        >
          {activeOverlays.includes('Contractors') && markers.map((marker) => (
            <Marker
              key={marker.id}
              longitude={marker.longitude}
              latitude={marker.latitude}
              color={marker.color}
              onClick={(e) => handleMarkerClick(e, marker)}
            />
          ))}
          {hoveredMarker && (
            <>
              <Source type="geojson" data={{
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates: [generateCirclePoints(hoveredMarker.latitude, hoveredMarker.longitude, parseFloat(hoveredMarker.travelDistance))]
                },
                properties: {
                  color: hoveredMarker.color
                }
              }}>
                <Layer
                  id={`circle-${hoveredMarker.id}`}
                  type="line"
                  paint={{
                    'line-color': ['get', 'color'],
                    'line-width': 2
                  }}
                />
              </Source>
              <Popup
                longitude={hoveredMarker.longitude}
                latitude={hoveredMarker.latitude}
                closeButton={false}
                closeOnClick={false}
                onClose={() => setHoveredMarker(null)}
                anchor="top"
              >
                <div 
                  className="marker-popup"
                  style={{ backgroundColor: hoveredMarker.color }}
                >
                  <h3>{hoveredMarker.id}</h3>
                  <p>Max Travel Distance: {parseFloat(hoveredMarker.travelDistance).toFixed(2)} miles</p>
                </div>
              </Popup>
            </>
          )}
          {activeOverlays.includes('Homeowner Density') && (
            <Source
              id="homeowners"
              type="geojson"
              data={{
                type: 'FeatureCollection',
                features: homeownerData.map(home => ({
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'Point',
                    coordinates: [home.geocoordinate.longitude, home.geocoordinate.latitude]
                  }
                }))
              }}
            >
              <Layer
                id="homeowner-heat"
                type="heatmap"
                paint={{
                  'heatmap-weight': 1,
                  'heatmap-intensity': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0, 1,
                    9, 3
                  ],
                  'heatmap-color': [
                    'interpolate',
                    ['linear'],
                    ['heatmap-density'],
                    0, 'rgba(33,102,172,0)',
                    0.2, 'rgb(103,169,207)',
                    0.4, 'rgb(209,229,240)',
                    0.6, 'rgb(253,219,199)',
                    0.8, 'rgb(239,138,98)',
                    1, 'rgb(178,24,43)'
                  ],
                  'heatmap-radius': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    0, 2,
                    9, 20
                  ],
                  'heatmap-opacity': 0.7
                }}
              />
            </Source>
          )}
        </Map>
      </div>
      {error && (
        <div className="error-popup">
          <p>{error}</p>
          <button onClick={() => setError(null)}>Close</button>
        </div>
      )}
    </div>
  );
}

export default MapReport;