import React from 'react';
import './Reports.css';

function Reports({ onSelectReport }) {
  const reports = [
    { id: 1, name: 'Location Map' },
    { id: 2, name: 'Monthly Statistics' },
    { id: 3, name: 'User Activity' },
    { id: 4, name: 'Financial Summary' },
  ];

  return (
    <div className="reports-container">
      <h2>Reports</h2>
      <div className="reports-grid">
        {reports.map((report) => (
          <button
            key={report.id}
            className="report-button"
            onClick={() => onSelectReport(report.id)}
          >
            {report.name}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Reports;