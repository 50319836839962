import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import Sidebar from './Sidebar';
import Homeowners from './Homeowners';
import Contractors from './Contractors';
import Reports from './Reports';
import MapReport from './MapReport';
import Support from './Support';
import './Dashboard.css';

function Dashboard({ user }) {
  const [activeView, setActiveView] = useState('homeowners');
  const [selectedReport, setSelectedReport] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const renderView = () => {
    switch (activeView) {
      case 'homeowners':
        return <Homeowners />;
      case 'contractors':
        return <Contractors />;
      case 'reports':
        return selectedReport ? (
          <MapReport onBack={() => setSelectedReport(null)} />
        ) : (
          <Reports onSelectReport={setSelectedReport} />
        );
      case 'support':
        return <Support currentUserEmail={user.email} />;
      default:
        return null;
    }
  };

  return (
    <div className={`dashboard ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
      {!selectedReport && (
        <Sidebar
          setActiveView={setActiveView}
          activeView={activeView}
          isCollapsed={isSidebarCollapsed}
          setIsCollapsed={setIsSidebarCollapsed}
        />
      )}
      <div className="dashboard__main-content">
        <div className="dashboard__user-info">
          <span className="dashboard__internal-tools">
            Internal Toolkit
            <span className="dashboard__beta-stamp">BETA</span>
          </span>
          <span className="dashboard__welcome-message">Welcome, {user.email}</span>
          <button className="dashboard__logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
        <div className="dashboard__view-wrapper">
          <div className="dashboard__view-container">{renderView()}</div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
