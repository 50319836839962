import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { FaKey, FaPencilAlt, FaCheck, FaTimes, FaSearch } from 'react-icons/fa';
import './Homeowners.css';
import axios from 'axios'; // Add this import

function Homeowners() {
  const [homeowners, setHomeowners] = useState([]);
  const [editingHomeowner, setEditingHomeowner] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [popup, setPopup] = useState({ visible: false, message: '', loading: false });

  useEffect(() => {
    fetchHomeowners();
  }, []);

  const fetchHomeowners = async () => {
    const db = getFirestore();
    const homeownersCollection = collection(db, 'prd_homeowners');
    const homeownersSnapshot = await getDocs(homeownersCollection);
    const homeownersList = homeownersSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setHomeowners(homeownersList);
  };

  const handleEdit = (homeowner) => {
    setEditingHomeowner(homeowner.id);
  };

  const handleSave = async (homeowner) => {
    const db = getFirestore();
    const homeownerRef = doc(db, 'prd_homeowners', homeowner.id);
    
    try {
      const { id, ...homeownerData } = homeowner;
      await updateDoc(homeownerRef, homeownerData);
      setHomeowners(homeowners.map(h => h.id === homeowner.id ? homeowner : h));
      setEditingHomeowner(null);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  const handleCancel = () => {
    setEditingHomeowner(null);
  };

  const handleInputChange = (homeowner, field, value) => {
    const updatedHomeowner = { ...homeowner, [field]: value };
    setHomeowners(homeowners.map(h => h.id === homeowner.id ? updatedHomeowner : h));
  };

  const handlePasswordReset = async (email) => {
    if (popup.visible) return; // Prevent re-clicking if popup is already visible
    setPopup({ visible: true, message: `Sending password reset email to ${email}`, loading: true });
    try {
      const response = await axios.post('https://stg-api-7jrujiqd5q-uc.a.run.app/password/request', 
        { email },
        {
          headers: {
            'X-API-KEY': 'jY0uVq5tP1a8sD3f6gH2b9N4cW0R' // Replace with your actual API key
          }
        }
      );
      setPopup({ visible: true, message: `Password reset email sent to ${email}`, loading: false });
    } catch (error) {
      setPopup({ visible: true, message: `Failed to send password reset email to ${email}`, loading: false });
      console.error("Error sending password reset email: ", error);
    }
  };

  const closePopup = () => {
    setPopup({ visible: false, message: '', loading: false });
  };

  const filteredHomeowners = homeowners.filter(homeowner => 
    homeowner.fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    homeowner.email?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="homeowners">
      <h2>Homeowners</h2>
      <div className="search-bar">
        <FaSearch />
        <input
          type="text"
          placeholder="Search homeowners..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="homeowners-table-container">
        <table className="homeowners-table">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredHomeowners.map(homeowner => (
              <tr key={homeowner.id}>
                <td>
                  {editingHomeowner === homeowner.id ? (
                    <input
                      type="text"
                      value={homeowner.fullname || ''}
                      onChange={(e) => handleInputChange(homeowner, 'fullname', e.target.value)}
                    />
                  ) : (
                    homeowner.fullname || 'N/A'
                  )}
                </td>
                <td>
                  {editingHomeowner === homeowner.id ? (
                    <input
                      type="text"
                      value={homeowner.email || ''}
                      onChange={(e) => handleInputChange(homeowner, 'email', e.target.value)}
                    />
                  ) : (
                    homeowner.email || 'N/A'
                  )}
                </td>
                <td>
                  {editingHomeowner === homeowner.id ? (
                    <>
                      <button onClick={() => handleSave(homeowner)} className="icon-button" data-tooltip="Save">
                        <FaCheck />
                      </button>
                      <button onClick={handleCancel} className="icon-button cancel" data-tooltip="Cancel">
                        <FaTimes />
                      </button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => handleEdit(homeowner)} className="icon-button edit" data-tooltip="Edit Homeowner">
                        <FaPencilAlt />
                      </button>
                      <button onClick={() => handlePasswordReset(homeowner.email)} className="icon-button key" data-tooltip="Send Password Reset Email">
                        <FaKey />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {popup.visible && (
        <div className="popup">
          <div className="popup-content">
            {popup.loading ? (
              <div className="loading-spinner"></div>
            ) : (
              <button onClick={closePopup} className="close-button">OK</button>
            )}
            <p>{popup.message}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Homeowners;