import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import './App.css';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCo272EimaB-Whpf5s6PWdVSNhHaVJLlws",
  authDomain: "home-runns.firebaseapp.com",
  databaseURL: "https://home-runns-default-rtdb.firebaseio.com",
  projectId: "home-runns",
  storageBucket: "home-runns.appspot.com",
  messagingSenderId: "530822910309",
  appId: "1:530822910309:web:c6b0ae9c6ce9be5ed4518c",
  measurementId: "G-MWV0Q2Z2D4"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email.endsWith('@homerunns.com')) {
        setUser(user);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <main className="content">
          <Routes>
            <Route
              path="/login"
              element={!user ? <Login /> : <Navigate to="/dashboard" />}
            />
            <Route
              path="/dashboard"
              element={user ? <Dashboard user={user} /> : <Navigate to="/login" />}
            />
            <Route
              path="*"
              element={<Navigate to={user ? "/dashboard" : "/login"} />}
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
