import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { FaSearch, FaFilter, FaTimes, FaCheck, FaTimesCircle, FaFileExcel, FaSort, FaSortUp, FaSortDown, FaUserTie } from 'react-icons/fa';
import './Contractors.css';
import axios from 'axios';
import ContractorEditModal from './ContractorEditModal';
import * as XLSX from 'xlsx';

const allServices = [
  "Carpentry", "Deep Cleaning", "Demolition", "Design", "Electrical", "Fencing",
  "Fireplace", "Flooring", "Garage Doors", "Handyman", "Home Technology", "HVAC",
  "Landscaping", "Painting", "Pest Control", "Plumbing", "Pool Maintenance",
  "Pressure Washing", "Remodeling", "Roofing", "Windows"
].sort();

function Contractors() {
  const [contractors, setContractors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [popup, setPopup] = useState({ visible: false, message: '', loading: false });
  const [serviceFilters, setServiceFilters] = useState([]);
  const popupTimeoutRef = useRef(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    fetchContractors();
  }, []);

  useEffect(() => {
    if (popup.visible && !popup.loading) {
      popupTimeoutRef.current = setTimeout(() => {
        setPopup(prev => ({ ...prev, visible: false }));
      }, 2000);
    }

    return () => {
      if (popupTimeoutRef.current) {
        clearTimeout(popupTimeoutRef.current);
      }
    };
  }, [popup.visible, popup.loading]);

  const fetchContractors = async () => {
    const db = getFirestore();
    const contractorsCollection = collection(db, 'prd_vendors');
    const contractorsSnapshot = await getDocs(contractorsCollection);
    const contractorsList = contractorsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    setContractors(contractorsList);
  };

  const handleSave = async (updatedContractor) => {
    const db = getFirestore();
    const contractorRef = doc(db, 'prd_vendors', updatedContractor.id);
    
    try {
      const { id, ...contractorData } = updatedContractor;
      
      // Update the document in Firebase
      await updateDoc(contractorRef, contractorData);

      // Update the local state
      setContractors(contractors.map(c => c.id === updatedContractor.id ? updatedContractor : c));
      setEditModalOpen(false);
      setSelectedContractor(null);

      // Show a success message
      setPopup({ visible: true, message: 'Contractor updated successfully', loading: false });
    } catch (error) {
      console.error("Error updating document: ", error);
      // Show an error message
      setPopup({ visible: true, message: 'Failed to update contractor', loading: false });
    }
  };

  const showPopup = (message, loading = false) => {
    if (popupTimeoutRef.current) {
      clearTimeout(popupTimeoutRef.current);
    }
    setPopup({ visible: true, message, loading });
  };

  const handlePasswordReset = async (email) => {
    if (window.confirm(`Are you sure you want to send a password reset email to ${email}?`)) {
      showPopup(`Sending password reset email to ${email}`, true);
      try {
        await axios.post('https://stg-api-7jrujiqd5q-uc.a.run.app/password/request', 
          { email },
          {
            headers: {
              'X-API-KEY': 'jY0uVq5tP1a8sD3f6gH2b9N4cW0R' // Replace with your actual API key
            }
          }
        );
        showPopup(`Password reset email sent to ${email}`);
      } catch (error) {
        showPopup(`Failed to send password reset email to ${email}`);
        console.error("Error sending password reset email: ", error);
      }
    }
  };

  const handleAddFilter = (e) => {
    const selectedService = e.target.value;
    if (selectedService && !serviceFilters.includes(selectedService)) {
      setServiceFilters([...serviceFilters, selectedService]);
    }
    e.target.value = ''; // Reset dropdown to default after selection
  };

  const handleRemoveFilter = (filter) => {
    setServiceFilters(serviceFilters.filter(f => f !== filter));
  };

  const filteredContractors = contractors.filter(contractor => {
    const searchableFields = [
      contractor.fullname,
      contractor.email,
      contractor.verified ? 'Yes' : 'No',
      contractor.stripeVerified ? 'Yes' : 'No',
      contractor.conInfo?.companyName,
      contractor.conInfo?.address1,
      // Add more searchable fields from conInfo
      ...Object.values(contractor.conInfo || {})
    ];
    
    const matchesSearch = searchableFields.some(field => 
      field && field.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );

    const matchesService = serviceFilters.length === 0 || 
      serviceFilters.some(filter => contractor.conInfo?.selectedServices?.includes(filter));

    return matchesSearch && matchesService;
  });

  const sortedContractors = useCallback(() => {
    let sortableContractors = [...filteredContractors];
    if (sortConfig.key !== null) {
      sortableContractors.sort((a, b) => {
        const aValue = sortConfig.key.includes('.') ? a.conInfo?.[sortConfig.key.split('.')[1]] : a[sortConfig.key];
        const bValue = sortConfig.key.includes('.') ? b.conInfo?.[sortConfig.key.split('.')[1]] : b[sortConfig.key];

        // Check for empty, null, or undefined values
        if (!aValue && aValue !== false && aValue !== 0) return 1;
        if (!bValue && bValue !== false && bValue !== 0) return -1;
        
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableContractors;
  }, [filteredContractors, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (name) => {
    if (!sortConfig.key) {
      return '';
    }
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  const handleRowClick = (contractor) => {
    setSelectedContractor(contractor);
    setEditModalOpen(true);
  };

  const exportToExcel = () => {
    const dataToExport = sortedContractors().map(contractor => ({
      'Company Name': contractor.conInfo?.companyName || '',
      'Full Name': contractor.fullname || '',
      'Email': contractor.email || '',
      'Services': contractor.conInfo?.selectedServices?.join(', ') || '',
      'Verified': contractor.verified ? 'Yes' : 'No',
      'Stripe Verified': contractor.stripeVerified ? 'Yes' : 'No',
      'Address': contractor.conInfo?.address1 || '',
      'Travel Distance': contractor.conInfo?.travelDistance || '',
      'Years of Service': contractor.conInfo?.yearsOfService || '',
      'Insurance': contractor.conInfo?.insurance || '',
      'Workers Comp': contractor.conInfo?.workersComp || '',
      'Bio': contractor.conInfo?.bio || ''
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Contractors");
    XLSX.writeFile(wb, "Contractors_Export.xlsx");
  };

  const renderSortIcon = (name) => {
    if (!sortConfig.key || sortConfig.key !== name) {
      return <FaSort className="sort-icon" />;
    }
    return sortConfig.direction === 'ascending' ? 
      <FaSortUp className="sort-icon" /> : 
      <FaSortDown className="sort-icon" />;
  };

  return (
    <div className="contractors">
      <h2><FaUserTie className="contractors-icon" /> Contractors</h2>
      <div className="search-container">
        <div className="search-bar">
          <div className="service-filter">
            <FaFilter />
            <select onChange={handleAddFilter} value="">
              <option value="">Add Filter</option>
              {allServices.filter(service => !serviceFilters.includes(service)).map(service => (
                <option key={service} value={service}>{service}</option>
              ))}
            </select>
          </div>
          <div className="search-input-wrapper">
            <FaSearch />
            <input
              type="text"
              placeholder="Search contractors..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button className="export-button" onClick={exportToExcel}>
            <FaFileExcel /> Export to Excel
          </button>
        </div>
      </div>
      {serviceFilters.length > 0 && (
        <div className="active-filters">
          {serviceFilters.map(filter => (
            <span key={filter} className="filter-tag">
              {filter}
              <FaTimes onClick={() => handleRemoveFilter(filter)} />
            </span>
          ))}
        </div>
      )}
      <div className="contractors-table-container">
        <table className="contractors-table">
          <thead>
            <tr>
              <th onClick={() => requestSort('conInfo.companyName')}>
                Company Name
                {renderSortIcon('conInfo.companyName')}
              </th>
              <th onClick={() => requestSort('fullname')}>
                Full Name
                {renderSortIcon('fullname')}
              </th>
              <th>Services</th>
              <th onClick={() => requestSort('email')}>
                Email
                {renderSortIcon('email')}
              </th>
              <th onClick={() => requestSort('verified')}>
                Verified
                {renderSortIcon('verified')}
              </th>
              <th onClick={() => requestSort('stripeVerified')}>
                Stripe Verified
                {renderSortIcon('stripeVerified')}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedContractors().map(contractor => (
              <tr key={contractor.id} onClick={() => handleRowClick(contractor)}>
                <td>{contractor.conInfo?.companyName || 'N/A'}</td>
                <td>{contractor.fullname || 'N/A'}</td>
                <td className="services-cell">
                  <div className="service-tags">
                    {contractor.conInfo?.selectedServices?.map(service => (
                      <span key={service} className="service-tag">{service}</span>
                    ))}
                  </div>
                </td>
                <td>{contractor.email || 'N/A'}</td>
                <td className="boolean-cell">
                  {contractor.verified ? (
                    <span className="boolean-indicator check-icon">
                      <FaCheck />
                    </span>
                  ) : (
                    <span className="boolean-indicator times-icon">
                      <FaTimesCircle />
                    </span>
                  )}
                </td>
                <td className="boolean-cell">
                  {contractor.stripeVerified ? (
                    <span className="boolean-indicator check-icon">
                      <FaCheck />
                    </span>
                  ) : (
                    <span className="boolean-indicator times-icon">
                      <FaTimesCircle />
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {editModalOpen && (
        <ContractorEditModal
          contractor={selectedContractor}
          onSave={handleSave}
          onClose={() => {
            setEditModalOpen(false);
            setSelectedContractor(null);
          }}
          onPasswordReset={handlePasswordReset}
        />
      )}
      <div className={`popup ${popup.visible ? 'visible' : ''}`}>
        <div className="popup-content">
          <p>{popup.message}</p>
          {popup.loading && <div className="loading-spinner"></div>}
        </div>
      </div>
    </div>
  );
}

export default Contractors;