import React, { useState, useRef, useEffect } from 'react';
import { FaTimes, FaEnvelope, FaKey } from 'react-icons/fa';
import './ContractorEditModal.css';

const allServices = [
  "Carpentry", "Deep Cleaning", "Demolition", "Design", "Electrical", "Fencing",
  "Fireplace", "Flooring", "Garage Doors", "Handyman", "Home Technology", "HVAC",
  "Landscaping", "Painting", "Pest Control", "Plumbing", "Pool Maintenance",
  "Pressure Washing", "Remodeling", "Roofing", "Windows"
].sort();

function ContractorEditModal({ contractor, onSave, onClose, onPasswordReset }) {
  const [editedContractor, setEditedContractor] = useState(contractor);
  const modalRef = useRef(null);
  const addServiceRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleInputChange = (field, value) => {
    setEditedContractor(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleConInfoChange = (field, value) => {
    setEditedContractor(prev => ({
      ...prev,
      conInfo: {
        ...prev.conInfo,
        [field]: value
      }
    }));
  };

  const handleServiceToggle = (service) => {
    const services = editedContractor.conInfo?.selectedServices || [];
    const updatedServices = services.includes(service)
      ? services.filter(s => s !== service)
      : [...services, service];
    handleConInfoChange('selectedServices', updatedServices);
  };

  const handlePasswordResetClick = () => {
    onPasswordReset(editedContractor.email);
  };

  const handleAddServiceClick = () => {
    if (addServiceRef.current) {
      addServiceRef.current.focus();
      addServiceRef.current.click();
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content" ref={modalRef}>
        <div className="modal-header">
          <h2>Edit Contractor</h2>
          <button className="close-button" onClick={onClose}><FaTimes /></button>
        </div>
        <div className="form-grid">
          <div className="form-column">
            <div className="form-group">
              <label>Company Name</label>
              <input
                type="text"
                value={editedContractor.conInfo?.companyName || ''}
                onChange={(e) => handleConInfoChange('companyName', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Display Name</label>
              <input
                type="text"
                value={editedContractor.conInfo?.displayName || ''}
                onChange={(e) => handleConInfoChange('displayName', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Address</label>
              <input
                type="text"
                value={editedContractor.conInfo?.address1 || ''}
                onChange={(e) => handleConInfoChange('address1', e.target.value)}
              />
            </div>
          </div>
          <div className="form-column">
            <div className="form-group">
              <label>Full Name</label>
              <input
                type="text"
                value={editedContractor.fullname || ''}
                onChange={(e) => handleInputChange('fullname', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={editedContractor.email || ''}
                onChange={(e) => handleInputChange('email', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <button className="reset-password-button" onClick={handlePasswordResetClick}>
                <FaKey />
                <span>Send Reset Link</span>
              </button>
            </div>
          </div>
          <div className="form-group full-width">
            <label>Services</label>
            <div className="services-tags">
              {editedContractor.conInfo?.selectedServices?.map(service => (
                <span key={service} className="service-tag">
                  {service}
                  <FaTimes onClick={() => handleServiceToggle(service)} />
                </span>
              ))}
              <select
                ref={addServiceRef}
                className="add-service"
                value=""
                onChange={(e) => {
                  if (e.target.value) {
                    handleServiceToggle(e.target.value);
                    e.target.value = '';
                  }
                }}
              >
                <option value="" disabled>+</option>
                {allServices
                  .filter(service => !editedContractor.conInfo?.selectedServices?.includes(service))
                  .map(service => (
                    <option key={service} value={service}>{service}</option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="bio-short-inputs-container">
            <div className="bio-container">
              <div className="form-group">
                <label>Bio</label>
                <textarea
                  value={editedContractor.conInfo?.bio || ''}
                  onChange={(e) => handleConInfoChange('bio', e.target.value)}
                />
              </div>
            </div>
            <div className="short-inputs-grid">
              <div className="short-input">
                <label>Distance</label>
                <input
                  type="text"
                  value={editedContractor.conInfo?.travelDistance || ''}
                  onChange={(e) => handleConInfoChange('travelDistance', e.target.value)}
                  maxLength="2"
                />
              </div>
              <div className="short-input">
                <label>Experience</label>
                <input
                  type="text"
                  value={editedContractor.conInfo?.yearsOfService || ''}
                  onChange={(e) => handleConInfoChange('yearsOfService', e.target.value)}
                  maxLength="2"
                />
              </div>
              <div className="short-input">
                <label>Insurance</label>
                <select
                  value={editedContractor.conInfo?.insurance || ''}
                  onChange={(e) => handleConInfoChange('insurance', e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div className="short-input">
                <label>Workers Comp</label>
                <select
                  value={editedContractor.conInfo?.workersComp || ''}
                  onChange={(e) => handleConInfoChange('workersComp', e.target.value)}
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="button-group">
          <button className="cancel-button" onClick={onClose}>Cancel</button>
          <button className="save-button" onClick={() => onSave(editedContractor)}>Save Changes</button>
        </div>
      </div>
    </div>
  );
}

export default ContractorEditModal;