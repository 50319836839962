import React, { useState } from 'react';
import { FaHeadset, FaPaperclip, FaTimes } from 'react-icons/fa';
import './Support.css';

function Support({ currentUserEmail }) {
  const [mode, setMode] = useState('internal');
  const [formData, setFormData] = useState({
    requestType: 'Feature Request',
    title: '',
    message: '',
    attachments: [],
    platform: 'iOS',
    userEmail: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFormData(prevState => ({
      ...prevState,
      attachments: [...prevState.attachments, ...newFiles]
    }));
  };

  const removeFile = (indexToRemove) => {
    setFormData(prevState => ({
      ...prevState,
      attachments: prevState.attachments.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');

    const formDataToSend = new FormData();
    
    // Set subject with [Internal] prefix
    formDataToSend.append('subject', `[Internal] ${formData.title}`);
    
    // Set email to support@homerunns.com
    formDataToSend.append('email', 'support@homerunns.com');

    // Prepare the body, including the Opened_By address
    const bodyContent = `
      Opened_By: ${currentUserEmail}
      Request Type: ${formData.requestType}
      ${mode === 'customer' ? `Platform: ${formData.platform}\n` : ''}
      ${mode === 'customer' ? `User Email: ${formData.userEmail}\n` : ''}
      Message: ${formData.message}
    `;
    formDataToSend.append('body', bodyContent);

    // Append files under the 'images' key
    for (let i = 0; i < formData.attachments.length; i++) {
      formDataToSend.append('images', formData.attachments[i]);
    }

    try {
      const response = await fetch('https://sendformdataemail-7jrujiqd5q-uc.a.run.app', {
        method: 'POST',
        headers: {
          'X-API-KEY': 'jY0uVq5tP1a8sD3f6gH2b9N4cW0R'
        },
        body: formDataToSend,
      });

      if (response.ok) {
        setSubmitMessage('Support request submitted successfully!');
        // Reset form after submission
        setFormData({
          requestType: mode === 'internal' ? 'Feature Request' : 'Technical Support',
          title: '',
          message: '',
          attachments: [],
          platform: 'iOS',
          userEmail: '',
        });
      } else {
        setSubmitMessage('Failed to submit support request. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitMessage('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModeChange = (newMode) => {
    setMode(newMode);
    setFormData(prevState => ({
      ...prevState,
      requestType: newMode === 'internal' ? 'Feature Request' : 'Technical Support',
    }));
  };

  return (
    <div className="support">
      <h2><FaHeadset className="support-icon" /> Open Support Request</h2>
      <div className="support-content">
        <div className="mode-toggle">
          <button 
            className={mode === 'internal' ? 'active' : ''}
            onClick={() => handleModeChange('internal')}
          >
            Internal Tool
          </button>
          <button 
            className={mode === 'customer' ? 'active' : ''}
            onClick={() => handleModeChange('customer')}
          >
            For Contractor or Homeowner
          </button>
        </div>
        <form onSubmit={handleSubmit} className="support-form">
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="requestType">Request Type</label>
              <select
                id="requestType"
                name="requestType"
                value={formData.requestType}
                onChange={handleChange}
                required
              >
                {mode === 'internal' ? (
                  <>
                    <option value="Feature Request">Feature Request</option>
                    <option value="Bug Fix">Bug Fix</option>
                    <option value="Support Inquiry">Support Inquiry</option>
                  </>
                ) : (
                  <>
                    <option value="Technical Support">Technical Support</option>
                    <option value="Feature Request">Feature Request</option>
                    <option value="Bug Report">Bug Report</option>
                  </>
                )}
              </select>
            </div>
            {mode === 'customer' && (
              <>
                <div className="form-group">
                  <label htmlFor="platform">Platform</label>
                  <select
                    id="platform"
                    name="platform"
                    value={formData.platform}
                    onChange={handleChange}
                    required
                  >
                    <option value="iOS">iOS</option>
                    <option value="Android">Android</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="userEmail">User Email</label>
                  <input
                    type="email"
                    id="userEmail"
                    name="userEmail"
                    value={formData.userEmail}
                    onChange={handleChange}
                    required
                  />
                </div>
              </>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="attachments" className="file-input-label">
              <FaPaperclip /> Attach Files
            </label>
            <input
              type="file"
              id="attachments"
              name="attachments"
              onChange={handleFileChange}
              multiple
              accept="image/*,video/*,.pdf,.doc,.docx,.txt"
              className="file-input"
            />
            {formData.attachments.length > 0 && (
              <div className="file-list">
                <p>{formData.attachments.length} file(s) attached:</p>
                <ul>
                  {formData.attachments.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <button 
                        type="button" 
                        onClick={() => removeFile(index)}
                        className="remove-file-btn"
                      >
                        <FaTimes />
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <button type="submit" className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
          {submitMessage && <p className="submit-message">{submitMessage}</p>}
        </form>
      </div>
    </div>
  );
}

export default Support;
